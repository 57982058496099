import React from "react"
import Typography from '@material-ui/core/Typography'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Link from '@material-ui/core/Link'
import ArticleLayout from '../components/article-layout'

const Instruo = () => {
  return (
    <Layout>
      <SEO title='Instruo'/>
      <ArticleLayout>
        <Typography variant='h1'>Instruo</Typography>
        <Typography variant='body1'>Instruo är flertal appar för barn som syftar till att stimulera språk- och matematikutveckling.</Typography>
        <Typography variant='body1'>Om du har några frågor eller feedback om apparna, välkommen att maila mig på <Link href='mailto:tomas.sjosten@gmail.com'>tomas.sjosten@gmail.com</Link></Typography>
      </ArticleLayout>
    </Layout>
  )
}

export default Instruo
